<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="代理机构">
          <el-select v-model="searchData.agent_id" filterable remote reserve-keyword placeholder="请输入关键词"
                     :remote-method="getAgentList" :loading="loading" size="mini">
            <el-option
                v-for="item in agentList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="账号">
          <el-input style="width: 200px" clearable v-model="searchData.account" size="mini"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input style="width: 200px" clearable v-model="searchData.mobile" size="mini"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="职位">
          <el-input style="width: 200px" clearable v-model="searchData.position" size="mini"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom: 10px" type="primary"
               icon="el-icon-plus"
               size="mini"
               plain
               @click="dialogVisible = true"
    >新增
    </el-button>
    <el-table :data="list">
      <el-table-column prop="id" label="ID" min-width="120"></el-table-column>
      <el-table-column prop="account" label="账号" min-width="120"></el-table-column>
      <el-table-column prop="status_dsc" label="状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag type="success" v-if="row.status === 20">{{ row.status_dsc }}</el-tag>
          <el-tag type="danger" v-else>{{ row.status_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="agent_name" label="代理机构" min-width="120">
        <template slot-scope="{ row }">
          {{ row?.agent?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="level_dsc" label="类型" min-width="120"></el-table-column>
      <el-table-column prop="mobile" label="手机号码" min-width="120"></el-table-column>
      <el-table-column prop="position" label="职位" min-width="120"></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
      <el-table-column label="操作" width="160" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">
            编辑
          </el-link>
          <el-link style="margin-right: 10px" :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
          <el-link :underline="false" type="success" @click="copyShareLink(row)">邀请链接</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize"
                @pagination="getList"/>

    <add-or-edit
        v-if="dialogVisible"
        :dialog-visible="dialogVisible"
        :id="currentId"
        @close-form="handleClose"
        @modify-success="modifySuccess"
    >
    </add-or-edit>
  </div>
</template>

<script>
import {listAPI, delAPI, listAgentAPI} from './api'
import addOrEdit from "./component/addOrEdit.vue";

export default {
  name: 'items',
  components: {addOrEdit},
  data() {
    return {
      dialogVisible: false,
      total: 0,
      currentId: 0,
      searchData: {
        page: 1,
        pagesize: 10,
        agent_id: '',
        account: '',
        mobile: '',
        position: '',
      },
      list: [],

      loading: false,
      agentList: [],
    }
  },

  mounted() {
    this.getList()
    this.getAgentList('')
  },

  methods: {
    copyShareLink({id}) {
      console.log(id, '@@@')
      let url = "https://www.bcbxw.cn/login?is_share=1&invite_code=" + id
      navigator.clipboard.writeText(url).then(() => {
        this.$message.success('复制成功')
      })
    },
    async getList() {
      const res = await listAPI(this.searchData)
      this.list = res.data || []
      this.total = res.total || 0
    },

    getAgentList(agentName) {
      this.loading = true;
      listAgentAPI({name: agentName}).then(result => {
        this.loading = false;
        this.agentList = result.data || [];
      })
    },

    handleEdit(row) {
      this.currentId = row.id
      this.dialogVisible = true
    },

    async handleDel({id}) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            delAPI({id}).then(() => {
              this.$message.success('删除成功')
              this.getList()
            })
          })
          .catch(() => {
            this.$message.info('取消删除')
          })
    },

    handleClose() {
      this.dialogVisible = false
      this.currentId = 0
    },

    modifySuccess() {
      this.dialogVisible = false
      this.currentId = 0
      this.getList()
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>


<style scoped lang="scss">

</style>
